import React, { useState } from 'react';
import { Link } from 'gatsby';
import { FaBars, FaTimes } from 'react-icons/fa';

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuItems = listClass => (
    <ul className={`${listClass} sm:flex`}>
      <li className="my-6 sm:mx-12 sm:my-0">
        <Link
          to="#about-me"
          className="text-white m-0 font-bold hover:text-yellow"
          onClick={() => setMenuOpen(false)}
        >
          About Me
        </Link>
      </li>
      <li className="my-6 sm:mx-12 sm:my-0">
        <Link
          to="#projects"
          className="text-white m-0 font-bold hover:text-yellow"
          onClick={() => setMenuOpen(false)}
        >
          Projects
        </Link>
      </li>
      <li className="my-6 sm:mx-12 sm:my-0">
        <Link
          to="#contact-me"
          className="text-white m-0 font-bold hover:text-yellow"
          onClick={() => setMenuOpen(false)}
        >
          Contact Me
        </Link>
      </li>
    </ul>
  );
  return (
    <header className={`p-6 absolute z-10 w-full bg-blue-900 ${isMenuOpen ? 'shadow-md  ' : ''}`}>
      <div className="container mx-auto flex items-center justify-end sm:justify-center ">
        <div className="hidden sm:block">{menuItems('mt-0')}</div>
        <div className="sm:hidden">
          <button className="p-2" type="button" onClick={() => setMenuOpen(!isMenuOpen)}>
            {isMenuOpen && <FaTimes className="text-white" />}
            {!isMenuOpen && <FaBars className="text-white" />}
          </button>
        </div>
      </div>
      <div className="sm:hidden relative bg-blue-900 ">
        <div className={`overflow-hidden pl-6 ${isMenuOpen ? 'h-auto' : 'h-0 hidden'}`}>
          {menuItems('mt-6')}
        </div>
      </div>
    </header>
  );
};

export default Header;
