import React from 'react';
import PropTypes from 'prop-types';

const Title = ({ alternate, id, children }) => (
  <h2 id={id} className={`text-4xl mb-6 font-bold ${alternate ? 'text-blue-900' : 'text-yellow'}`}>
    {children}
  </h2>
);

Title.propTypes = {
  alternate: PropTypes.bool,
  id: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

Title.defaultProps = {
  alternate: false,
};

export default Title;
