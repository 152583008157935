/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';

import Header from './header';
import './layout.css';

const Layout = ({ centered, children, hasFooter, mainClassName }) => (
  <div className="min-h-screen flex flex-col">
    <Header />
    <div className="flex flex-grow flex-col bg-blue-900 mt-6 pt-6">
      <main
        className={`flex-grow sm:px-0 sm:pt-6 ${mainClassName} ${
          centered ? 'flex items-center justify-center' : ''
        }`}
      >
        {children}
      </main>
    </div>
    {hasFooter && (
      <footer className="p-6 text-right text-white bg-blue-900">
        {'© '}
        {new Date().getFullYear()}
        {' | Powered by '}
        <a className="font-bold" href="/">
          me!
        </a>
      </footer>
    )}
  </div>
);

Layout.propTypes = {
  centered: PropTypes.bool,
  children: PropTypes.node.isRequired,
  hasFooter: PropTypes.bool,
  mainClassName: PropTypes.string,
};

Layout.defaultProps = {
  centered: false,
  hasFooter: true,
  mainClassName: '',
};

export default Layout;
